.video {
    position: relative;
    height: 100vh; /* Full height */
    width: 100%; /* Full width */
    overflow: hidden;

    #background-video {
        position: absolute;
        // min-width: 100vw;
        // max-height: 100vh;
        // min-height: 100vh;
        width: 120%;
        // min-height: 100%;
        // max-width: 120%;
        z-index: -1;
        // transform: rotate(90deg);
        background-size: cover;
        // filter: brightness(0.7); /* Optional: adjust brightness */
    }

    &__data {
        position: relative;
        margin: 0 auto;
        background-color: rgba(0, 0, 0, 0.4);
        padding: 30px;
        margin-top: 100px;
        max-width: 600px;
        width: calc(100% - 60px);

        form {
            align-items: center;
            display: flex;
            flex-direction: column;
            justify-content: center;

            > p {
                color: white;

                > span {
                    cursor: pointer;
                    text-decoration: underline;
                }
            }

            input {
                font-family: "Nunito", sans-serif;
                background-color: rgb(254, 201, 236);
                border: none;
                height: 50px;
                margin: 10px 0 0 0;
                padding: 0 10px;
                width: calc(100% - 20px);

                &:disabled {
                    background-color: rgb(225, 225, 225);
                }
            }

            textarea {
                font-family: "Nunito", sans-serif;
                background-color: rgb(254, 201, 236);
                border: none;
                height: 90px;
                margin-top: 10px;
                padding: 5px 10px 0 10px;
                width: calc(100% - 20px);

                &:disabled {
                    background-color: rgb(225, 225, 225);
                }
            }

            .checkbox {
                align-items: center;
                display: flex;
                flex-direction: row;
                margin-top: 15px;
                width: 100%;

                > input {
                    margin: 0;
                    margin-right: 10px;
                    width: unset;
                }

                span {
                    color: white;
                }
            }

            input[type="submit"] {
                font-family: "Nunito", sans-serif;
                background-color: rgb(1, 158, 1);
                cursor: pointer;
                color: white;
                font-weight: 500;
                height: 30px;
                width: 100px;

                &:disabled {
                    background-color: rgb(124, 124, 124);
                    cursor: default;
                }
            }
        }
    }
}
