.app {
  align-items: center;
  background-image: url('./assets/background.avif');
  background-size: cover;
  height: 100vh;
  display: flex;
  justify-content: center;
  width: 100%;

  &__container {
    background-color: rgba(0, 0, 0, 0.3);
    padding: 30px;
    width: clamp(300px, 100%, 600px);

    form {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;

      > p {
        color: white;

        > span {
          cursor: pointer;
          text-decoration: underline;
        }
      }

      input {
        font-family: "Nunito", sans-serif;
        background-color: rgb(254, 201, 236);
        border: none;
        height: 30px;
        margin: 10px 0 0 0;
        padding: 0 10px;
        width: calc(100% - 20px);

        &:disabled {
          background-color: rgb(225, 225, 225);
        }
      }
      
      textarea {
        font-family: "Nunito", sans-serif;
        background-color: rgb(254, 201, 236);
        border: none;
        height: 60px;
        margin-top: 10px;
        padding: 5px 10px 0 10px;
        width: calc(100% - 20px);

        &:disabled {
          background-color: rgb(225, 225, 225);
        }
      }

      .checkbox {
        align-items: center;
        display: flex;
        flex-direction: row;
        margin-top: 15px;
        width: 100%;

        > input {
          margin: 0;
          margin-right: 10px;
          width: unset;
        }
      }
      
      input[type='submit'] {
        font-family: "Nunito", sans-serif;
        background-color: rgb(1, 158, 1);
        cursor: pointer;
        color: white;
        font-weight: 500;
        height: 30px;
        width: 100px;

        &:disabled {
          background-color: rgb(124, 124, 124);
          cursor: default;
        }
      }
    }
  }
}


